import React from 'react';
import PropTypes from 'prop-types';
import * as styles from './Footer.module.scss';
import textWithWhiteSpace from '../../utils/textWithWhiteSpace';

import Button from '../Button/Button';
import Pattern from '../../images/Pattern-footer.svg';

const Footer = ({ data }) => {
  const { text, link } = data;

  if (!text) return null;

  return (
    <section className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.mainContent}>
          <h6>Neste</h6>
          <h2 className={styles.nextPageTitle}>{textWithWhiteSpace(text)}</h2>
        </div>
        <div className={styles.ctaContent}>
          <Button text="Neste" href={link.path} />
        </div>
      </div>
      <Pattern className={styles.pattern} />
      <p className={styles.slogan}>Telia - Norges største utfordrer</p>
    </section>
  );
};

Footer.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.shape({
      title: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

export default Footer;
