import debounce from 'just-debounce-it';

const URL_BAR_THESHOLD = (function () {
  return 156;
})();

let lastWidth = 0;
let lastHeight = 0;

const setVh = debounce(() => {
  const width = window.innerWidth;
  const height = window.innerHeight;
  const deltaHeight = Math.abs(height - lastHeight);

  if (width !== lastWidth || deltaHeight > URL_BAR_THESHOLD) {
    window.document.documentElement.style.setProperty('--vh', `${height * 0.01}px`);
  }

  lastWidth = width;
  lastHeight = height;
}, 350);

if (typeof window !== 'undefined') {
  window.addEventListener('resize', setVh);
  setVh();
}
