/*
	External dependencies
*/
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import '../utils/setVh';

/*
	Components
*/
import App from '../components/App/App';
import Components from '../components/Components';
import Nav from '../components/Nav/Nav';
import Footer from '../components/Footer/Footer';
import VideoBanner from '../components/VideoBanner/VideoBanner';

const Home = ({ data }) => {
  const { hero, contentBlocks, footer } = data.sanityPageDocument;

  return (
    <App>
      <Nav theme="light" bg />
      <VideoBanner block={hero} isHero />
      {contentBlocks.map((block) => Components(block))}
      <Footer data={footer} />
    </App>
  );
};

export const query = graphql`
  {
    sanityPageDocument(_id: { eq: "homePage" }) {
      id
      hero {
        _key
        _type
        ctaText
        video {
          asset {
            playbackId
            filename
          }
        }
        title
        mobileVideo {
          asset {
            playbackId
            filename
          }
        }
        loopVideo {
          asset {
            playbackId
            filename
          }
        }
        poster {
          asset {
            url
          }
        }
      }
      contentBlocks {
        ... on SanityTextSection {
          _key
          _type
          _rawText
          bgcolor
          heading
        }
        ... on SanityPebbleTextAnimation {
          _key
          _type
          first
          second
          third
        }
        ... on SanityChat {
          _key
          _type
          messages {
            isTelia
            message
            big
          }
        }
      }
      footer {
        text
        link {
          path
          title
        }
      }
    }
  }
`;

Home.propTypes = {
  data: PropTypes.shape({
    sanityPageDocument: PropTypes.shape({
      hero: PropTypes.shape,
      contentBlocks: PropTypes.arrayOf(PropTypes.shape),
    }),
  }),
};

export default Home;
